import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeVi from '@angular/common/locales/vi';
import { Component, HostListener, OnInit } from '@angular/core';
import { ResponseData } from 'src/app/core/models/interfaces/common';
import {
  ModuleAdminEnum,
  Permission,
} from 'src/app/core/models/interfaces/permission';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';
import { User } from '../../components/header/header.component';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  readonly MODULE_ADMIN = ModuleAdminEnum.FINANCE;
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  stateView: string = STATE_RENDER_APP.INIT;
  user: User;

  constructor(
    private httpClient: HttpClient,
    private translator: CustomTranslateService,
  ) {}

  ngOnInit() {
    const lang = this.translator.getCurrentLanguage();
    this.translator.setLanguage(lang);
    registerLocaleData(localeVi, 'vi');
    this.checkSmallScreen();
  }

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  @HostListener('window:message', ['$event'])
  handleReceiveMessage(e: MessageEvent) {
    if (e?.data?.type === 'authorized') {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));
        this.user = {
          fullName: profileData?.full_name,
          avatarURL: `${environment.BE_URL}storage/files/web/${profileData?.avatar_thumbnail_url}.webp`,
        };
        this.onCheckPermission();
      } else {
        window.location.href = `${environment.ADMIN_HUB_URL}/login?r=${environment.FE_URL}`;
      }
    }
  }

  onCheckPermission() {
    this.httpClient
      .get<ResponseData<Permission[]>>(
        `${environment.BE_URL}user/admin/roles/module`,
      )
      .subscribe({
        next: (res) => {
          const permissions = res.data ?? [];
          const isHavePermission = permissions.find(
            (permission) =>
              permission.type_moderator === this.MODULE_ADMIN ||
              permission.role === 'SUPER_ADMIN',
          );
          if (isHavePermission) {
            this.stateView = STATE_RENDER_APP.APP;
          } else {
            window.location.href = `${environment.ADMIN_HUB_URL}`;
          }
        },
        error: () => {
          window.location.href = `${environment.ADMIN_HUB_URL}`;
        },
      });
  }
}
